@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@font-face {
  font-family: abadi;
  src: url("abadi.otf");
}
:root {
  --accent-color: #00acb4;
  --accent-color-2: #A33CFC;
  --text-color: #132237;
}
*{scroll-behavior: smooth;}
body {
  font-family: abadi;
  background: #F9FAFF;
  color: black;
  overflow-x: hidden;
}

.app {
  width: 100%;
  margin-inline: auto;
  position: relative;
}

nav {
  width: 500px;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.4);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 16px;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  gap: 75px;
  margin-top: 20px;
  padding: 20px 0;
  z-index: 100;
}

nav a {
  text-decoration: none;
  color: black;
  font-weight: 700;
  font-size: 18px;
}
nav a:hover{
  color: var(--accent-color-2);
}

.home {
  width: 100%;
  margin-inline: auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  height: 100vh;
  background: #edeef2;
  clip-path: polygon(0 0, 44% 0, 70% 100%, 0% 100%);
}

.home .info {
  width: 550px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: 100px;
}

.wrapper-home {
  width: 100%;
  height: 100vh;
  background: url("profile2.jpg") #edeef2;
  background-size: contain;
  background-position: right bottom;
  background-repeat: no-repeat;
}

.info h1 {
  font-size: 60px;
  font-weight: 800;
  color: var(--text-color);
  text-align: left;
  line-height: 55px;
  margin: 0;
  margin-left: 0;
}
.info h2{
  font-size: 22px;
  font-weight: 500;
  color: var(--text-color);
  text-align: left;
  line-height: 55px;
  margin: 0;
  margin-left: 0;
  margin-bottom: 50px;

}

.info p {
  text-align: left;
  font-size: 20px;
  font-weight: 600;
  color: var(--text-color);
  margin-left: 0;
  margin-top: 50px;
  margin-bottom: 0px;
}

.info hr {
  width: 70%;
  height: 6px;
  background: var(--accent-color-2);
  border: none;
  margin: 0;
}

.social-info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 100px;
  flex-direction: column;

}

.flex-socials {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

.flex-socials img {
  width: 30px;
  background: white;
  padding: 8px;
  border-radius: 50%;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--accent-color-2);
  color: white;
  padding: 10px 40px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 700;
  text-decoration: none !important;
  transition: 0.2s all ease;
}
.flex-socials a{
  transition: 0.2s all ease;
}
.button:hover, .flex-socials a:hover{
  filter: brightness(0.9);
  border-radius: 10px;
}

.about{
  width: 100%;
  margin: 0;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.about h1{
  font-size: 50px;
  margin-bottom: 0;
}
.about hr{
  width: 40%;
  height: 4px;
  background: var(--accent-color-2);
  border: none;
  margin: 0;
}
.about p{
  font-weight: 600;
  font-size: 20px;
}
.card{
  margin-top: 50px;
  width: 1100px;
  padding-inline: 40px;
  height: 700px;
  background: white;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  transition: 0.2s all ease;
}
.card:hover, .card2:hover{
  box-shadow: 0 4px 35px #a23cfc4b;
}
.left{
  width: calc(60%);
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.left h2{
  font-size: 40px;
  font-weight: 800;
  margin: 0;
  text-align: left;
}

.right{
  width: 40%;
  position: relative;
  
}
.swiper {
  width: 100%;
  height: 100%;
  background: transparent!important;

}
.swiper-slide {
  width: 100%;
  height: 100%;
  display: flex!important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  background: transparent!important;
}
.swiper-button-next, .swiper-button-prev{
  color: var(--accent-color-2)!important;
}

.swiper-slide img {
  width: 100%;
  max-height: 80%;
  border-radius: 10px;
  user-select: none!important;
}
#contact{
  width: 100%;
  margin: 0;
  padding: 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
.card2{
  margin-top: 50px;
  width: 1100px;
  padding-inline: 40px;
  padding-block: 80px;
  background: white;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
}
.left2{
  width: 40%;
}
.left2 h2{
  font-size: 60px;
  font-weight: 800;
  margin: 0;
  text-align: left;
}
.left2 p{
  font-size: 18px;
  font-weight: 600;
}
.right2{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.right2 a{
  color: var(--accent-color-2);
  font-size: 26px;
  font-weight: 700;
}
footer{
  width: 100%;
  text-align: center;
  margin-top: 100px;
}
.fadeIn {
  opacity: 0;
  transform: translate3d(-50px, 0, 0);
  /* ADDED - start x position as -50px */
  animation: fadeIn 1s forwards ease-in-out;
}
.about{
  position: relative;
}
.ellipse1{
  position: absolute;
  top: 100px;
  right: -100px;
  z-index: -1;
  opacity: 0.5; 
}

.ellipse1 img{
  width: 600px;
  height: 600px;
}
.ellipse2{
  position: absolute;
  top: 100px;
  left: -200px;
  z-index: -1;
  opacity: 0.5; 
}
.ellipse2 img{
  width: 400px;
  height: 400px;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translate3d(-50px, 0, 0);
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
@media(max-width: 1250px){
  .home {
    clip-path: polygon(0 0, 55% 0, 70% 100%, 0% 100%);
  }
}
h1,h2,h3,h4,h5,h6,p{
  user-select: none !important;
}
.hide-800 {
  display: flex;
}
.menu-button {
  position: absolute;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 30px;
  height: 30px;
  top: 2rem;
  right: 20px;
  z-index: 110;
  transition: 0.3s all ease-in-out;

}

.menu-button .line-top {
  position: relative;
  height: 2px;
  background: var(--accent-color-2);
  width: 100%;
  transition: 0.3s all ease-in-out;
}

.menu-button .line-bottom {
  position: relative;
  height: 2px;
  background: var(--accent-color-2);
  width: 100%;
  transition: 0.3s all ease-in-out;
}

.close-button .line-top {
  transform: rotate(45deg) translateY(2px);
}

.close-button .line-bottom {
  transform: rotate(-45deg) translateY(-1px);
}

.close-button {
  gap: 0px;
  transition: 0.3s all ease-in-out;
  z-index: 110;
}

.mobile-nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);

  z-index: -1;
  transition: 0.3s all ease-in-out;
  opacity: 0;
}

.mobile-nav-inner {
  padding-top: 100px;
  padding-left: 24px;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 15px;

}

.divider {
  width: 100%;
  height: 1px;
  background: rgba(255, 255, 255, 0.075);
}

.mobile-nav a {
  color: white;
  font-size: 20px;
  font-weight: 800;
  text-decoration: none;
}

.open-menu {
  opacity: 1;
  z-index: 100;
}
@media(max-width: 1000px){
  .hide-800 {
    display: none;
  }
  .menu-button {
    display: flex;
    position: fixed;
  }
  .home .info{
    width: calc(100% - 40px);
    padding: 0 20px;
    margin: 0;
  }
  .wrapper-home{
    background: none;
  }
  .home{
    clip-path: none;
  }
  .info h1{
    font-size: 46px;
  }
  .info p{
    font-size: 18px;
  }
  .mobile-photo{
    height: 400px;
    width: 100%;
    background: url(./profile2.jpg);
    background-size: cover;
    background-position: bottom;
    position: relative;
  }
  .fadeBot{
    height: 40px;
    width: 100%;
    background: linear-gradient(180deg, #edeef200 0%, #edeef2 100%);
    position: absolute;
    bottom: -2px;
    left: 0;
  }
  .home{
    height: calc(100vh - 200px);
  }
  .social-info{
    gap: 50px;
  }
  .card{
    flex-direction: column;
    width: calc(90% - 40px);
    padding: 40px 20px;
    height: auto;
  }
  .about{
    padding: 100px 0;
    overflow-x: hidden;
  }
  .ellipse1{
    right: -400px;
  }
  .left{
    width: 100%;
  }
  .right{
    width: 100%;
    height: 400px;
  }
  .card2{
    flex-direction: column;
    width: calc(90% - 40px);
    padding: 40px 20px;
    height: auto;
  }
  .left2{
    width: 100%;
  }
  .about h1{
    font-size: 36px;
  }
  .left h2{
    font-size: 24px;
  }
  .left2 h2{
    font-size: 36px;
  }
  
  .info p, .left2 p{
    font-size: 16px;
  }
  .wrapper-home{
    min-height: 100vh;
    height: auto;
  }
  .home{
    min-height: 100vh;
  }
}